import React from 'react'
import Layout from '../share/layout'
import { useLocation } from "@reach/router"
import ShareButton from '../share/ShareButton'
import Styles from './template6.module.css'

const UrlPathname = () => {
	let { pathname } = useLocation();
	return pathname
}

const template6 = ({ pageContext }) => {
	const { ShareImage, embedlink, metaDescripcin, metaKeywords, metaTitle, title } = pageContext.data;
	return (
		<Layout
			descritpion={metaDescripcin}
			imgurl={ShareImage.file.url}
			subtitile={metaDescripcin}
			title={title}
			metaTitle={metaTitle}
			metaKeywords={metaKeywords}
			metaDescripcin={metaDescripcin}
		>
			<h1 className="d-none">{title}</h1>
			<h2 className="d-none">{metaDescripcin}</h2>
			<ShareButton url={UrlPathname()} />
			<div className={Styles.h_100vh}>
				<iframe title={title} className={Styles.iframe} src={embedlink} allow="autoplay; fullscreen"/>
			</div>
		</Layout>
	)
}

export default template6
